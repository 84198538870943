import { graphql } from 'gatsby';
import { withLang } from '../../utils/reactTools';
import { Team } from '../team';

export const pageQuery = graphql`
  {
    globalSEO: markdownRemark(fileAbsolutePath: { regex: "/misc/seo.md/" }) {
      fields {
        locales {
          fr {
            ...SeoFragmentFR
          }
        }
      }
    }

    markdownRemark(fileAbsolutePath: { regex: "/pages/team.md/" }) {
      fields {
        locales {
          fr {
            pageTheme
            ...SeoFragmentFR
            ...heroBannerFragmentFR
            ...contentBannerFragmentFR
            ...employeesFragmentFR
            ...textBannerFragmentFR
            ...teamCompositionFragmentFR
          }
          en {
            pageTheme
            ...SeoFragmentEN
            ...heroBannerFragmentEN
          }
        }
      }
    }
  }
`;

export default withLang(Team, 'fr');
